// External
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { SheetManager, type SheetProps } from 'react-native-actions-sheet'
import { object, type InferType } from 'yup'
// Components
import { Button, Input, RadioButton, Text } from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'
// Models
import { CustomFieldTypes, type components } from '@/common/models'
// Services
import { fillCustomField, updateCustomField } from '@/profile/services'
// Utils
import { handleError } from '@/common/utils'
import { generateCustomFieldSchema } from '@/profile/utils'

type Props = SheetProps<'edit-custom-field'>

export const EditCustomField = ({ payload, sheetId }: Props) => {
  const { t } = useTranslation()

  const validationSchema = object({
    [payload?.field.uuid as string]: generateCustomFieldSchema(
      payload?.field as components['schemas']['GetAppUserCustomFieldResponseItem']
    )
  })

  type FormValues = InferType<typeof validationSchema>

  const onSubmit = async (values: FormValues) => {
    try {
      if (payload?.isFilled === true) {
        await updateCustomField({
          uuid: payload?.field.uuid,
          value: values[payload?.field.uuid] as string
        })
      } else {
        await fillCustomField({
          customFieldId: payload?.field.customFieldId as string,
          value: values[payload?.field.uuid as string] as string
        })
      }
      await payload?.refetch()
      toast.success({
        data: {
          messageTranslationKey: 'profileUpdated'
        }
      })
      await SheetManager.hide(sheetId)
    } catch (error) {
      handleError(error)
    }
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      [payload?.field.uuid as string]: payload?.field.value as string
    }
  })

  return (
    <ActionSheetContainer id={sheetId} isModal={false}>
      <Text
        style={{
          textAlign: 'center',
          marginBottom: 30
        }}
        variant="baseBold"
      >
        {t('editExtraField')}
      </Text>

      <Controller
        control={control}
        name={payload?.field.uuid as string}
        render={({ field: { onChange, onBlur, value } }) => {
          if (payload?.field.type === CustomFieldTypes.BOOLEAN) {
            return (
              <View>
                <Text variant="label">{payload?.field.translation.name}</Text>

                <View style={styles.booleanOptions}>
                  <View style={styles.booleanOption}>
                    <Text variant="small">{t('yes')}</Text>

                    <RadioButton
                      checked={value === 'true'}
                      onPress={() => {
                        onChange('true')
                        onBlur()
                      }}
                    />
                  </View>

                  <View style={styles.booleanOption}>
                    <Text variant="small">{t('no')}</Text>

                    <RadioButton
                      checked={value === 'false'}
                      onPress={() => {
                        onChange('false')
                        onBlur()
                      }}
                    />
                  </View>
                </View>

                <Text
                  style={{ margin: 5 }}
                  variant="extraSmall"
                  color={colors.error}
                >
                  {errors[payload?.field.uuid]?.message}
                </Text>
              </View>
            )
          }

          return (
            <Input
              label={payload?.field.translation.name as string}
              placeholder={payload?.field.translation.placeholder}
              onChangeText={onChange}
              onBlur={onBlur}
              value={value}
              returnKeyType="done"
              {...(payload?.field.type === CustomFieldTypes.NUMBER && {
                keyboardType: 'numeric'
              })}
              onSubmitEditing={handleSubmit(onSubmit)}
              errorMessage={errors[payload?.field.uuid as string]?.message}
            />
          )
        }}
      />

      <Button
        style={{ marginTop: 18 }}
        title={t('saveChanges')}
        onPress={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </ActionSheetContainer>
  )
}

const styles = StyleSheet.create({
  booleanOptions: {
    flexDirection: 'row',
    gap: 8
  },
  booleanOption: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})
