// External
import { type DrawerScreenProps } from '@react-navigation/drawer'
import { type CompositeScreenProps } from '@react-navigation/native'
import { type StackScreenProps } from '@react-navigation/stack'
import { Divider } from '@rneui/themed'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View
} from 'react-native'
// Components
import { EmailVerificationAlert } from '@/auth/components'
import {
  DeleteAccountButton,
  Icon,
  PoweredBy,
  SettingsItem,
  Text,
  TopBar
} from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
// Hooks
import useRefetchOnFocus from '@/common/hooks/useRefetchOnFocus'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import { type PreHomeStackParamList } from '@/common/models'
import type { FindFacilityDrawerParamList } from '@/findFacility/models'
// Services
import { getSettings } from '@/common/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import {
  modifyLocationSettings,
  modifyMotionSettings
} from '@/geoposition/utils'
import { modifyNotificationSettings } from '@/notifications/utils'
import { useEffect } from 'react'

type Props = CompositeScreenProps<
  DrawerScreenProps<FindFacilityDrawerParamList, 'Settings'>,
  StackScreenProps<PreHomeStackParamList>
>

const Settings = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { showModal, hideModal } = usePortalStore((state) => ({
    showModal: state.showModal,
    hideModal: state.hideModal
  }))
  const { currentModules, logOut } = useAppStore((state) => ({
    currentModules: state.currentModules,
    logOut: state.logOut
  }))

  const { data, error, isError, isLoading, refetch } = useQuery({
    queryKey: ['settings', currentModules],
    queryFn: async () => await getSettings()
    // As of 31/01/2025 this does not work God knows why
    // enabled: Platform.OS !== 'web'
  })

  useEffect(() => {
    if (isError) {
      toast.error({
        data: {
          messageTranslationKey: error.message
        }
      })
      navigation.goBack()
    }
  }, [isError, error])

  useRefetchOnFocus([refetch])

  return (
    <SafeArea
      style={{ backgroundColor: '#F9F9FB' }}
      edges={['top', 'left', 'right']}
    >
      <TopBar
        style={{ marginHorizontal: 25 }}
        title={t('settings')}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
        renderLanguageDropdown
      />

      <ScrollView
        contentContainerStyle={{ flex: 1, paddingHorizontal: 25 }}
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refetch} />
        }
      >
        <EmailVerificationAlert />

        {Platform.OS !== 'web' && (
          <>
            <Text variant="smallBold" style={{ marginTop: 34 }}>
              {t('systemDetails')}
            </Text>

            <View
              style={[styles.systemDetails, { backgroundColor: colors.white }]}
            >
              <SettingsItem
                iconName="notifications"
                value={data?.notificationsGranted ?? false}
                text={t('notifications')}
                onValueChange={modifyNotificationSettings}
                onPress={modifyNotificationSettings}
              />

              <Divider />

              <SettingsItem
                iconName="location"
                value={(data?.fgGranted ?? false) && (data?.bgGranted ?? false)}
                text={t('location')}
                onValueChange={modifyLocationSettings}
                onPress={modifyLocationSettings}
              />

              <Divider />

              <SettingsItem
                iconName="device-motion"
                value={data?.motionGranted ?? false}
                text={t('deviceMotion')}
                onValueChange={modifyMotionSettings}
                onPress={modifyMotionSettings}
              />
            </View>
          </>
        )}

        {Platform.OS !== 'web' && (
          <>
            <Text variant="smallBold" style={{ marginTop: 34 }}>
              {t('geoposition')}
            </Text>

            <View
              style={[styles.systemDetails, { backgroundColor: colors.white }]}
            >
              <View style={styles.systemDetailsItem}>
                <Text variant="smallBold">{t('tracking')}</Text>

                <Text style={{ marginStart: 'auto' }} variant="small">
                  {data?.enabled ?? false ? t('on') : t('off')}
                </Text>
              </View>

              <Divider />

              <View style={styles.systemDetailsItem}>
                <Text variant="smallBold">{t('mode')}</Text>

                <Text style={{ marginStart: 'auto' }} variant="small">
                  {data?.trackingMode === 1
                    ? t('locationPlusGeofences')
                    : t('geofencesOnly')}
                </Text>
              </View>
            </View>
          </>
        )}

        <DeleteAccountButton
          style={styles.openDeleteModalButton}
          onPress={() => {
            showModal({
              content: (
                <View>
                  <View style={styles.deleteAccountDisclaimer}>
                    <View style={{ flexShrink: 1 }}>
                      <Text variant="smallBold">{t('deleteYourAccount')}</Text>

                      <Text variant="small">
                        {t('deleteAccountDisclaimer')}
                      </Text>
                    </View>

                    <Icon color={colors.error} name="alert-fill" size={32} />
                  </View>

                  <DeleteAccountButton
                    onPress={() => {
                      logOut()
                      toast.success({
                        data: {
                          titleTranslationKey: 'accountDeletedTitle',
                          messageTranslationKey: 'accountDeletedMessage'
                        }
                      })
                      hideModal()
                    }}
                  />
                </View>
              )
            })
          }}
        />

        <PoweredBy />
      </ScrollView>
    </SafeArea>
  )
}

export default Settings

const styles = StyleSheet.create({
  systemDetails: {
    marginTop: 10,
    borderRadius: 7,
    paddingHorizontal: 20
  },
  systemDetailsItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 21,
    gap: 20
  },
  openDeleteModalButton: {
    marginTop: 20,
    marginBottom: 34
  },
  deleteAccountDisclaimer: {
    marginBottom: 34,
    flexDirection: 'row',
    gap: 30,
    justifyContent: 'space-between'
  }
})
