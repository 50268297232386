// External
import type { StackScreenProps } from '@react-navigation/stack'
import { zonedTimeToUtc } from 'date-fns-tz'
import { createRef, type RefObject } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ScrollView,
  StyleSheet,
  View,
  type TextInput,
  type TouchableOpacity
} from 'react-native'
// Components
import {
  Button,
  DateInput,
  Input,
  RadioButton,
  Text,
  TopBar
} from '@/common/components'
import { CustomFieldAttachmentList } from '@/profile/components'
// Constants
import { colors } from '@/common/constants'
// Layouts
import { KeyboardFix, SafeArea } from '@/common/layouts'
// Models
import {
  CustomFieldTypes,
  type CustomFieldsStackParamList
} from '@/common/models'
import type { CustomFieldsFormValues } from '@/profile/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { fillCustomFields } from '@/profile/useCases'
// Utils
import { handleError } from '@/common/utils'
import { generateCustomFieldSchema } from '@/profile/utils'

type Props = StackScreenProps<CustomFieldsStackParamList, 'CustomFields'>

const CustomFields = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { missingCustomFields, setMissingCustomFields, currentFacility } =
    useAppStore((state) => ({
      missingCustomFields: state.missingCustomFields,
      setMissingCustomFields: state.setMissingCustomFields,
      currentFacility: state.currentFacility
    }))

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<CustomFieldsFormValues>({
    defaultValues: {
      customFields: missingCustomFields
        .filter((field) => field.type !== CustomFieldTypes.ATTACHMENTS)
        .map((field) => ({
          id: field._id,
          ref: createRef(),
          type: field.type,
          label: field.translation.name,
          placeholder: field.translation.placeholder,
          autoComplete: field.autoComplete,
          schema: generateCustomFieldSchema(field),
          isRequired: field.isRequired
        })),
      attachmentCustomFields: missingCustomFields
        .filter((field) => field.type === CustomFieldTypes.ATTACHMENTS)
        .map((field) => ({
          id: field._id,
          name: field.translation.name,
          description: field.translation.description,
          placeholder: field.translation.placeholder,
          value: [],
          isRequired: field.isRequired
        }))
    }
  })

  const { fields: customFields } = useFieldArray({
    control,
    name: 'customFields'
  })

  const { fields: attachmentCustomFields } = useFieldArray({
    control,
    name: 'attachmentCustomFields'
  })

  const onSubmitEditing = (index: number) => {
    if (index < customFields.length - 1) {
      const nextField = customFields[index + 1]
      if (nextField !== undefined) {
        if (
          [CustomFieldTypes.NUMBER, CustomFieldTypes.TEXT].includes(
            nextField.type
          )
        ) {
          // @ts-expect-error - in this case ref is a RefObject<TextInput>
          nextField.ref?.current?.focus()
          return
        }

        if (nextField.type === CustomFieldTypes.DATE) {
          // @ts-expect-error - in this case the ref is customized via useImperativeHandle, not assigned
          nextField.ref?.current?.openCalendar()
        }
      }
    }

    if (index === customFields.length - 1) {
      handleSubmit(onSubmit)
    }
  }

  const onSubmit = async (values: CustomFieldsFormValues) => {
    try {
      await fillCustomFields({
        // @ts-expect-error - params are created from OpenAPI schema which is incorrect since value can be a boolean
        customFields: values.customFields
          .filter(
            (field) =>
              field.value !== undefined &&
              (field.value !== '' || typeof field.value === 'boolean')
          )
          .map((field) => ({
            customFieldId: field.id,
            value: field.value
          })),
        attachmentCustomFields: values.attachmentCustomFields
          .filter((field) => field.value.length > 0)
          .map((field) => ({
            id: field.id,
            value: field.value
          }))
      })
      setMissingCustomFields([])
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <SafeArea>
      <KeyboardFix contentContainerStyle={{ flex: 1 }}>
        <TopBar
          style={{ paddingHorizontal: 25 }}
          title={t('extraFields')}
          leftIcon={{
            name: 'back',
            onPress: () => {
              navigation.replace('SelectYourUserType')
            }
          }}
        />

        <ScrollView
          bounces={false}
          contentContainerStyle={styles.scrollContentContainer}
        >
          <Text style={{ marginBottom: 27.5 }}>
            {t('requiresAdditionalInformation', {
              facilityName: currentFacility?.name
            })}
          </Text>

          {customFields.map((field, index) => (
            <Controller
              key={field.id}
              control={control}
              name={`customFields.${index}`}
              render={({ field: { onBlur, onChange, value } }) => {
                const commonProps = {
                  onSubmitEditing: () => {
                    onSubmitEditing(index)
                  },
                  placeholder: value.placeholder,
                  errorMessage: errors?.customFields?.[index]?.message,
                  label:
                    value.isRequired ?? false
                      ? value.label
                      : t('optional', { label: value.label })
                }

                if (field.type === CustomFieldTypes.BOOLEAN) {
                  return (
                    <View>
                      <Text variant="label">{commonProps.label}</Text>

                      <View style={styles.booleanOptions}>
                        <View style={styles.booleanOption}>
                          <Text variant="small">{t('yes')}</Text>

                          <RadioButton
                            checked={value.value === true}
                            onPress={() => {
                              onChange({ ...value, value: true })
                              onBlur()
                            }}
                          />
                        </View>

                        <View style={styles.booleanOption}>
                          <Text variant="small">{t('no')}</Text>

                          <RadioButton
                            checked={value.value === false}
                            onPress={() => {
                              onChange({ ...value, value: false })
                              onBlur()
                            }}
                          />
                        </View>
                      </View>

                      <Text
                        style={{ margin: 5 }}
                        variant="extraSmall"
                        color={colors.error}
                      >
                        {commonProps.errorMessage}
                      </Text>
                    </View>
                  )
                }

                if (field.type === CustomFieldTypes.DATE) {
                  return (
                    <DateInput
                      ref={field.ref as RefObject<TouchableOpacity>}
                      value={
                        value.value !== undefined &&
                        typeof value.value === 'string' &&
                        value.value.length > 0
                          ? new Date(value.value)
                          : undefined
                      }
                      onDayPress={(data) => {
                        let date = new Date(data.year, data.month - 1, data.day)

                        if (currentFacility?.info.timezone !== undefined) {
                          date = zonedTimeToUtc(
                            new Date(data.year, data.month - 1, data.day),
                            currentFacility?.info.timezone
                          )
                        }

                        onChange({
                          ...value,
                          value: date.toISOString()
                        })
                        onBlur()
                      }}
                      {...commonProps}
                    />
                  )
                }

                return (
                  <Input
                    ref={field.ref as RefObject<TextInput>}
                    value={value.value as string | undefined}
                    onChangeText={(text) => {
                      onChange({ ...value, value: text })
                    }}
                    onBlur={onBlur}
                    returnKeyType={
                      index === customFields.length - 1 ? 'done' : 'next'
                    }
                    {...(field.type === CustomFieldTypes.NUMBER && {
                      keyboardType: 'numeric'
                    })}
                    autoComplete={field.autoComplete}
                    {...commonProps}
                  />
                )
              }}
              rules={{
                validate: async (field) => {
                  try {
                    if (field.isRequired) {
                      await field.schema.validate(field.value)
                    }
                    return true
                  } catch (error) {
                    if (error instanceof Error) {
                      return error.message
                    }
                  }
                }
              }}
            />
          ))}

          {attachmentCustomFields.map((field, index) => (
            <Controller
              key={field.id}
              control={control}
              name={`attachmentCustomFields.${index}`}
              render={({ field: { onBlur, onChange, value } }) => (
                <CustomFieldAttachmentList
                  attachments={value.value}
                  onAddAttachment={(attachment) => {
                    onChange({ ...value, value: [...value.value, attachment] })
                    onBlur()
                  }}
                  onRemoveAttachment={(index) => {
                    onChange({
                      ...value,
                      value: value.value.filter((_, i) => i !== index)
                    })
                    onBlur()
                  }}
                  name={value.name}
                  description={value.description}
                  placeholder={value.placeholder}
                  errorMessage={
                    errors?.attachmentCustomFields?.[index]?.message
                  }
                  isRequired={field.isRequired}
                />
              )}
              rules={{
                validate: (field) => {
                  if (field.value.length === 0 && field.isRequired) {
                    return t('requiredField')
                  }

                  return true
                }
              }}
            />
          ))}
        </ScrollView>

        <Button
          title={t('save')}
          style={styles.button}
          onPress={handleSubmit(onSubmit)}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </KeyboardFix>
    </SafeArea>
  )
}

export default CustomFields

const styles = StyleSheet.create({
  scrollContentContainer: {
    paddingTop: 23,
    paddingHorizontal: 25,
    paddingBottom: 25
  },
  button: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  booleanOptions: {
    flexDirection: 'row',
    gap: 8
  },
  booleanOption: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})
